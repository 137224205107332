$(document).ready(function () {
  $('#menu-box').click(function () {
    $('body').toggleClass('body--hidden')
    $('#header').toggleClass('header--open')
  })
  $("#CallContact").click(function() {
    $("body").toggleClass("body--hidden");
    $("#CallContactModal").toggleClass("overlay--open");
  });
  $( "#CallContactClose" ).click(function() {
    $("#CallContactModal").removeClass("overlay--open");
    $("body").removeClass("body--hidden");
  });
})


$(function () {
  //caches a jQuery object containing the header element
  var header = $('.header__wrap')
  $(window).scroll(function () {
    var scroll = $(window).scrollTop()
    if (scroll >= 40) {
      header.addClass('header__wrap--scroll')
    }
  })
  $(window).scroll(function () {
    var scroll = $(window).scrollTop()
    if (scroll <= 10) {
      header.removeClass('header__wrap--scroll')
    }
  })
})

var footerSelect = document.getElementsByClassName('footer__select')

for (var i = 0; i < footerSelect.length; i++) {
  footerSelect[i].onclick = function (event) {
    this.classList.toggle('footer__select--active')
  }
}

function giveContact() {
  document.getElementById('giveContactBtn').style.display = 'none'
  document.getElementById('giveContactBox').style.display = 'grid'
}
